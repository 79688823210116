import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useRef, useState} from 'react';
import {STAGING_BASE_URL, WEEKS_BY_SKIN} from "../../Axios/EndPoints";
import {get} from "../../Axios/get";
import moment from "moment-timezone";
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import '../../assets/styles/components/matches/weeklist/weeklistStyle.css';
import '../../assets/styles/fonts.css';
import SmallLoader from "../SmallLoader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import {Button} from "react-bootstrap";

const WeeksList = (props) => {

    const {isMobile, skinId, weekId, setCurrentWeek, setSelectedWeek, setSelectedPicks, blogPage} = props;
    const navigation = useNavigate();
    const state = useLocation();
    const swiperRef = useRef(null);
    const dispatch = useDispatch();
    const [params] = useSearchParams();

    const user = useSelector(state => state.Login);
    const [weeks, setWeeks] = useState([]);
    const [weeksLoading, setWeeksLoading] = useState(true);
    const [activeIndex, setActiveIndex] = useState(-1);

    const fetchWeek = () => {
        setSelectedPicks(null);
        let encrypted = false;
        let token = '';
        if (user.token) {
            encrypted = true;
            token = user.token;
        }
        let endpoint = WEEKS_BY_SKIN;
        endpoint = endpoint.replace('__SKIN_ID__', skinId);
        let isStaging = params.get('staging');
        get(endpoint, encrypted, token, dispatch, isStaging ? STAGING_BASE_URL : '')
            .then((response) => {
                setWeeks(response.data.data);
                setWeeksLoading(false);
                if (isNaN(weekId)) {
                    response?.data?.data.map((value, index) => {
                        if (value.is_active) {
                            navigation(`${value.id}`, {state: state.state});
                            /*setActiveIndex(index);
                            setCurrentWeek(true);
                            setSelectedWeek(value);*/
                        }
                    });
                } else {
                    response?.data?.data.map((value, index) => {
                        if (parseInt(value.id) === weekId) {
                            setActiveIndex(index);
                            setSelectedWeek(value);
                        }
                    });
                }
            })
            .catch((error) => {
                setWeeksLoading(false);
                console.error('get_weeks_error', error);
            });
    }

    useEffect(() => {
        fetchWeek();
    }, [skinId, weekId]);

    useEffect(() => {
        if (weeks.length) {
            weeks.map((value, index) => {
                if (parseInt(value.id) === weekId) {
                    setActiveIndex(index);
                    setSelectedWeek(value);
                    if (value.is_active) {
                        setActiveIndex(index);
                        setCurrentWeek(true);
                    } else {
                        setCurrentWeek(false);
                    }
                }
            });
        }
        swiperRef.current.swiper.slideTo(activeIndex);
    }, [activeIndex, weekId]);

    const handleSlidePrev = () => {
        swiperRef.current.swiper.slidePrev();
    };
    const handleSlideNext = () => {
        swiperRef.current.swiper.slideNext();
    };


    const renderMobile = () => {
        return (
            <section aria-label={'Weeks List'} className={'calendar-container mb-5'}>
                <SmallLoader loading={weeksLoading} className={'text-black'}/>
                <div className={'text-white justify-content-center d-flex align-items-center '}>
                    <Swiper
                        slidesPerView={4}
                        ref={swiperRef}
                        initialSlide={2}
                        className={"w-100"}
                    >
                        <div onClick={handleSlidePrev}
                             className="swiper-button-prev"></div>
                        {
                            weeks.map((value, index) => (
                                <SwiperSlide key={`weeks_list_${index}`}
                                             className={'d-flex justify-content-center'}
                                             aria-current={parseInt(value.id) === weekId}
                                >
                                    <li className={` ${parseInt(value.id) === weekId ?
                                        'calendar-active' :
                                        'calendar-not-active'} list-inline-item text-center`}>
                                        <Button
                                            onClick={() => navigation(`/matches/${skinId}/${value.id}`, {state: state.state})}
                                            className={'btn bg-transparent border-0'}>
                                            <FontAwesomeIcon icon={faCheckCircle}
                                                             className={value.is_finished ? 'text-winning' : 'text-transparent'}/>
                                            <div className={`${parseInt(value.id) === weekId ?
                                                'fw-bold' :
                                                'fw-regular'} text-light-black text-uppercase roboto font-25`}>
                                                {value.week}
                                            </div>
                                            <div className={`${parseInt(value.id) === weekId ?
                                                'fw-bold' :
                                                'fw-regular'} text-light-black text-uppercase roboto font-15`}>
                                                {moment(value.end_date).format('D MMM')}
                                            </div>
                                        </Button>
                                    </li>
                                </SwiperSlide>
                            ))
                        }
                        <div onClick={handleSlideNext}
                             className="swiper-button-next"></div>

                    </Swiper>
                </div>
            </section>
        );
    }

    const renderDesktop = () => {
        return (
            <section aria-label={'Weeks List'} className={'calendar-container mt-5 mb-5'}>
                <SmallLoader loading={weeksLoading}/>
                <div className={'text-white justify-content-center align-items-center '}>
                    <Swiper
                        slidesPerView={8}
                        ref={swiperRef}
                        initialSlide={activeIndex}
                    >
                        <div onClick={handleSlidePrev}
                             className="swiper-button-prev"></div>
                        {
                            weeks.map((value, index) => (
                                <SwiperSlide key={`weeks_list_${index}`}
                                             className={'d-flex justify-content-center'}
                                >
                                    <li className={` ${parseInt(value.id) === weekId ?
                                        'calendar-active' :
                                        'calendar-not-active'} list-inline-item text-center`}>
                                        <Button
                                            onClick={() => navigation(`/matches/${skinId}/${value.id}`, {state: state.state})}
                                            className={'btn btn-dark bg-transparent border-0'}>
                                            {/*<FontAwesomeIcon icon={faCheckCircle}
                                                             className={value.is_finished ? 'text-winning' : 'text-transparent'}/>*/}
                                            <div className={`${parseInt(value.id) === weekId ?
                                                'text-light-black' :
                                                'text-light'} fw-800 text-uppercase roboto font-25`}>
                                                {value.week}
                                            </div>
                                            <div className={`${parseInt(value.id) === weekId ?
                                                'text-light-black' :
                                                ''} fw-800 text-uppercase roboto font-15`}>
                                                {moment(value.end_date).format('D MMM')}
                                            </div>
                                        </Button>
                                    </li>
                                </SwiperSlide>
                            ))
                        }
                        <div onClick={handleSlideNext}
                             className="swiper-button-next"></div>

                    </Swiper>
                </div>
            </section>
        );
    }

    const render = () => {
        if (isMobile) {
            return renderMobile();
        }

        return renderDesktop();
    }

    return render();

}

WeeksList.propTypes = {
    isMobile: PropTypes.bool.isRequired,
    skinId: PropTypes.number.isRequired,
    weekId: PropTypes.number.isRequired,
    setCurrentWeek: PropTypes.func.isRequired,
    setSelectedWeek: PropTypes.func.isRequired,
    setSelectedPicks: PropTypes.func,
    blogPage: PropTypes.bool,
}

WeeksList.defaultPrps = {
    blogPage: false
}

export default WeeksList;
