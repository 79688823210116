import PropTypes from "prop-types";
import {Col, ListGroup, Row} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import SkinListGroupItem from "./SkinListGroupItem";
import icon1 from '../assets/images/wp-content/uploads/2021/11/How-do-I-play-icon-1.svg';

const HowToPlayComponent = (props) => {
    const {isMobile} = props;

    const render = () => {
        return (
            <Container fluid>
                <Row>
                    <Col xxl={5} xl={5} lg={12} md={12} sm={12}>
                        <img className={'img-fluid'}
                             src={require('../assets/images/home/how_to_play.png')}
                             alt={'Home iPhone Skins Section 3'}/>
                    </Col>
                    <Col xxl={7} xl={7} lg={12} md={12} sm={12}>
                        <div className={'text-center'}>
                                <span className={`mb-0 mt-5 text-skins-red cabin text-center font-48 fw-bold`}
                                      role={'heading'} aria-level={1}>
                                How do I Play?
                            </span>
                            <br></br>
                            <span className={'text-black font-17 fw-bold ms-2'} role={'note'}>
                                Simply follow the next 4 steps.
                            </span>
                        </div>

                        <div>
                            <ListGroup defaultActiveKey="#item_1">
                                <SkinListGroupItem itemKey={'item_1'} current={true} isMobile={isMobile}
                                                   image={icon1}
                                                   text={'Select a team from the fixture list that you think will WIN that Gameweek. If your selection wins, congratulations you are through to the following gameweek. Unfortunately if you lose or draw then you are out.'}
                                />
                                <SkinListGroupItem itemKey={'item_2'} current={false} isMobile={isMobile}
                                                   image={require('../assets/images/wp-content/uploads/elementor/thumbs/Rules-2-Icon-q360xj61m9p69murniq4i268z1xkmi2cfmu9tbn8wo.png')}
                                                   text={'If you made it through just select a team to win from the next gameweek fixture list. However, and here is the catch, this time you may no longer select a team that you have used in a previous week, therefore there are only 19 teams available to you in week 2, 18 in week 3 and so on. It’s as simple as that.'}
                                />
                                <SkinListGroupItem itemKey={'item_3'} current={false} isMobile={isMobile}
                                                   image={require('../assets/images/wp-content/uploads/elementor/thumbs/Rules-3-Icon-q360xj61nl2902s1uszopj2ask3sna02xl0hqkrik6.png')}
                                                   text={'Navigate your way to the end, which is historically 9-11 gameweeks regardless of the number of entries, and the prize pot is yours.'}
                                />
                                <SkinListGroupItem itemKey={'item_4'} current={false} isMobile={isMobile}
                                                   image={require('../assets/images/wp-content/uploads/elementor/thumbs/Rules-4-Icon-q360xj61m9p69murniq4i268z1xkmi2cfmu9tbn8wo.png')}
                                                   text={'You can have a maximum of 3 picks per game. Each entry = £10.'}
                                />
                            </ListGroup>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }

    return render();
}

HowToPlayComponent.propTypes = {
    isMobile: PropTypes.bool.isRequired
}

export default HowToPlayComponent;
